import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import { Amplify } from "aws-amplify";
import { AVPlugin } from "vue-audio-visual";

loadFonts();
console.log("Host Name", window.location.hostname);
async function initApp() {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: window.location.hostname === "bms.quizzr.in" ? "ap-south-1_Hhia0FNAL" : "ap-south-1_5ca9FnBX2",
        userPoolClientId: window.location.hostname === "bms.quizzr.in" ? "57uormvg78qbiim28dujd93jfj" : "1sf1didq224afakp7nbfgou43u",
        loginWith: {
          email: true,
        },
        signUpVerificationMethod: "code",
      },
    },
    API: {
      GraphQL: {
        endpoint:
          window.location.hostname === "bms.quizzr.in"
            ? "https://aegyoabkhzaxlm65cabgqgha3m.appsync-api.ap-south-1.amazonaws.com/graphql"
            : "https://ukfkgf5elrdxld3y6llrarihlu.appsync-api.ap-south-1.amazonaws.com/graphql",
        region: "ap-south-1",
        defaultAuthMode: "userPool",
      },
    },
  });
 
}
initApp().then(() => {
  createApp(App).use(router).use(store).use(vuetify).use(AVPlugin).mount("#app");
});
