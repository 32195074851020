<template>
  <v-app>
    <Snackbar :SnackbarComponent="SnackbarComponent" />
    <v-row no-gutters class="main-container">
      <v-col cols="12" md="6" class="LeftBackground"></v-col>
      <v-col cols="12" md="6" class="RightBackground"></v-col>
      <v-col cols="12" class="d-flex justify-center align-center card-container">
        <v-card class="pa-4 d-flex align-center mb-6" height="500px" width="800px">
          <v-row :no-gutters="true" class="align-center">
            <v-col cols="6" align="center">
              <v-img max-width="180px" class="pt-0" src="@/assets/logo1_without_bg2.png" />
              <div class="pt-2 FontSize20px font-weight-bold text-primary FontFamilyVariant1 px-2">We make learning fun and engaging for everyone through quizzes, crosswords, and puzzles.</div>
            </v-col>
            <v-col cols="6" align="center">
              <v-card class="RightCardColor rounded-0 pt-16" height="500px" width="400px">
                <div class="text-center pt-4 FontSize30px font-weight-bold text-white FontFamilyVariant1">BMS</div>
                <div class="text-center FontSize24px font-weight-bold text-white FontFamilyVariant1">Sign Into Your Account</div>
                <v-form class="pt-8 mx-6" ref="LoginForm">
                  <v-text-field
                    class="text-body-1"
                    variant="solo"
                    density="compact"
                    placeholder="Email ID"
                    :disabled="IsOTPFieldEnabled"
                    :filled="IsOTPFieldEnabled"
                    v-model="Login.email_id"
                    :rules="[(v) => !!v || 'required']"
                    @keypress.enter.prevent="ValidateMethod(IsOTPFieldEnabled ? 'VERIFY_OTP' : 'SEND_OTP')"
                  >
                    <template v-slot:placeholder>
                      <div>Email ID</div>
                    </template>
                  </v-text-field>
                </v-form>
                <div class="mx-7 text-left FontSize12px font-weight-bold text-white" v-if="IsOTPFieldEnabled">You will receive an OTP in your email for verification.</div>
                <v-otp-input
                  v-show="IsOTPFieldEnabled"
                  color="white"
                  v-model="Login.otp"
                  :rules="[(v) => !!v || 'required']"
                  focus-all
                  ref="otpInput"
                  focused
                  class="FontSize12px OTPField mx-7"
                  @keypress.enter.prevent="ValidateMethod(IsOTPFieldEnabled ? 'VERIFY_OTP' : 'SEND_OTP')"
                ></v-otp-input>
                <div class="mx-7 text-left">
                  <v-btn
                    v-if="IsOTPFieldEnabled && counter === 0"
                    size="small"
                    variant="text"
                    color="white"
                    :disabled="counter !== 0"
                    class="text-capitalize pa-0 ma-0"
                    @click="SignInMethod('SEND_OTP')"
                    >Resend OTP</v-btn
                  >
                  <span v-if="IsOTPFieldEnabled == true && counter !== 0" class="text-yellowColorVariant1 font-weight-bold FontSize12px">Resend OTP available in {{ counter }}</span>
                </div>
                <v-card class="mx-6 rounded-0 mt-2">
                  <v-btn
                    size="large"
                    block
                    color="yellowColorVariant1"
                    variant="flat"
                    class="rounded-0 custom-transform-class text-none FontFamilyVariant1 font-weight-bold FontSize20px"
                    :loading="LoginLoader"
                    @click="ValidateMethod(IsOTPFieldEnabled ? 'VERIFY_OTP' : 'SEND_OTP')"
                    >{{ IsOTPFieldEnabled ? "Verify OTP" : "Login" }}</v-btn
                  >
                </v-card>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import { signIn, confirmSignIn, signOut } from "aws-amplify/auth";
import Snackbar from "@/components/Extras/Snackbar.vue";
import { useDisplay } from "vuetify";
export default {
  components: {
    Snackbar,
  },
  data: () => ({
    CardHeight: 0,
    counter: 0,
    user: null,
    interval: null,
    OTPErrorMessage: "",
    testOTP: "",

    LoginLoader: false,
    IsOTPFieldEnabled: false,

    SnackbarComponent: {},
    Login: {
      email_id: "",
      otp: "",
    },
  }),
  watch: {
    "Login.otp"() {
      this.MoveCursorToLastColumnMethod();
    },
    "Login.email_id"(val) {
      if (val && val !== null) {
        this.Login.email_id = val.toLowerCase();
      }
    },
  },
  created() {
    const display = useDisplay();
    this.CardHeight = display.height;
    this.$watch(
      () => display.height,
      (newHeight) => {
        this.CardHeight = newHeight;
      }
    );
  },
  mounted() {},
  methods: {
    MoveCursorToLastColumnMethod() {
      const otpInput = this.$refs.otpInput.$el || this.$refs.otpInput;

      const inputs = otpInput.querySelectorAll("input");
      if (inputs.length > 0) {
        inputs[inputs.length - 2].focus();
      }
    },

    ValidateMethod(action) {
      if (this.$refs.LoginForm.validate()) {
        this.SignInMethod(action);
      } else {
        this.SnackbarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "redColorVariant3",
          SnackbarText: `Please enter Email to Login`,
        };
      }
    },
    async SignInMethod(action) {
      switch (action) {
        case "SEND_OTP":
          try {
            this.LoginLoader = true;
            this.user = await signIn({
              username: this.Login.email_id,
              options: {
                authFlowType: "CUSTOM_WITHOUT_SRP",
              },
            });
            console.log("User", this.user.nextStep.signInStep);
            if (this.user.nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE") {
              this.SnackbarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "primary",
                SnackbarText: `Verification OTP sent to ${this.Login.email_id}`,
              };
              this.counter = 120;
              this.LoginLoader = false;
              this.IsOTPFieldEnabled = true;
              setTimeout(() => {}, 1000);
              this.$forceUpdate();
              this.startCounter();
            }
          } catch (error) {
            if (error.message === "DefineAuthChallenge failed with error Error: NOT_AUTHORIZED : Kindly Sigup.") {
              this.SignInMethod("SIGNUP");
            } else if (error.message === "There is already a signed in user.") {
              await signOut({ global: true });
              this.SignInMethod("SEND_OTP");
            } else {
              this.LoginLoader = false;
              this.SnackbarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "redColorVariant3",
                SnackbarText: error.message,
              };
            }

            console.log("Err", error);
          }
          break;
        case "VERIFY_OTP":
          try {
            this.LoginLoader = true;
            await confirmSignIn({ challengeResponse: this.Login.otp })
              .then(() => {
                this.SnackbarComponent = {
                  SnackbarVmodel: true,
                  SnackbarColor: "primary",
                  SnackbarText: "Login successful",
                };
                this.LoginLoader = false;
                this.ActivateMethod();
              })
              .catch((err) => {
                if (err.message === "VerifyAuthChallengeResponse failed with error Incorrect OTP!!." || err.message === "Invalid session for the user.") {
                  this.counter = 0;
                  this.Login.otp = "";
                }
                this.SnackbarComponent = {
                  SnackbarVmodel: true,
                  SnackbarColor: "redColorVariant3",
                  SnackbarText: err.message,
                };
                this.OTPErrorMessage = err.message;
                this.LoginLoader = false;
              });
          } catch (error) {
            console.log("err", error);
          }
          break;
      }
    },
    disableBrowserRoutingChanges() {
      window.history.pushState(null, "", window.location.href);
      window.onpopstate = function () {
        window.history.pushState(null, "", window.location.href);
      };
    },
    startCounter() {
      this.interval = setInterval(() => {
        if (this.counter > 0) {
          this.counter--;
        } else {
          clearInterval(this.interval);
        }
      }, 1000);
    },
    beforeDestroy() {
      clearInterval(this.interval);
    },
    ActivateMethod() {
      sessionStorage.setItem("user_email_id", this.Login.email_id);
      setTimeout(() => {
        this.$router.push("LandingPage");
      }, 1500);
    },
  },
};
</script>

<style>
.main-container {
  height: 100vh !important;
}

.LeftBackground {
  /* background: linear-gradient(132deg,  #212335, #591BC5, #FC415A); */
  background: linear-gradient(132deg, #00c6dd, #24156d, #6cfacd);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100vh !important;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.RightBackground {
  background-color: white !important;
  height: 100% !important;
}

.card-container {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}
.OTPField .v-otp-input__content {
  padding: 0 !important;
  max-width: 500px !important;
}
.RightCardColor {
  background-image: linear-gradient(132deg, #00c6dd, #24156d, #6cfacd) !important;
  top: 50% !important;
  background-size: 400% 400% !important;
  animation: gradient 15s ease infinite !important;
}
</style>
